.small-box {
  margin-bottom: 0px;
}

.small-box-custom-styling, .small-box-single-custom-styling {
  display: grid;
  background-color: #222227;
  border-radius: 10px;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 50% 50%;
  padding: 0.5rem;

  @media (min-width:1280px) {
    grid-template-columns: 1fr 5fr;
  }
  .icon-styling {
    grid-row: 1 / 2;
    margin-top:0.25rem;
    align-self: center;
    text-align: center;
    img {
        height: 2.25rem;
        width: 2.25rem;
    }
  }

  p {
    text-align: start;
  }

  p:first-of-type {
    grid-row-start: 1;
    grid-row-end: 2;
    font-size: calc(12px + 1.5vw + 1.5vh);
    align-self: center;
  }

  p:last-of-type {
    grid-column-start: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    font-size: calc(12px + 0.5vw);
    align-self: start;
  }
}

.small-box-single-custom-styling{
    height: 112px;
    grid-template-columns: 1fr 2fr;
    p:first-of-type {
        font-size: calc(12px + 1.25vw + 1.25vh);
        @media (max-width:1280px) {
          padding-left: 10px;
        }
    }
    p:last-of-type {
        font-size: calc(12px + 0.25vw);
        @media (max-width:1280px) {
          grid-column: 1 / 3;
          text-align: center;
          align-self: center;
      }
    }


}