.nav_item:link {
    color: #fff;
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-decoration: none;
}

.nav_item:visited {
    color: #fff;
}

.nav_item:visited:hover {
    color: yellow;
}

.nav_item:active {
    color: teal;
}

.nav-sidebar>.nav-item>.nav-link.custom-link-active.active {
    background-color: rgba(128, 128, 128, 0.25);
}