.modal-background {
    position: fixed; /* Stay in place */
    z-index: 10000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.dialogue {
    margin: 0 auto;
    padding: 20px;
    width: 500px;
}

.modal-content {
    background-color: #343a40;
}

.modal-header {
background-color: #343a40;
}

.modal-title{
    color: white;
    font-weight: 900;
}