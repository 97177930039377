@import './styles/variables.scss';

.App {
  text-align: center;
  background-color: #1e1e20;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-header .navbar-nav .nav-item .nav-link {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.navbar-nav > .user-menu .user-image {
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 0;
  margin-left: -8px;
}

.center {
  position: absolute;
  left: 50%;
  // top: 50%;
  transform: translate(-50%, -50%);
}

/* Hide INPUT spinner for type=number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] { /* Firefox */
  -moz-appearance: textfield;
}
