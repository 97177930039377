@import "../../styles/variables.scss";

@import url("https://fonts.googleapis.com/css?family=Jura:400");

* {
  font-weight: 400;
  font-family: "Varela Round", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

section#Login {
  display: grid;
  grid-template-columns: 1fr minmax(200px, 400px) 1fr;
  grid-template-rows: 1fr minmax(auto, 1fr) 1fr;
  grid-gap: 10px;
  width: 100%;
  height: 100vh;
  // background: linear-gradient(-45deg, #000000, #b31e1e, #c0c0c0, #000000);
  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  // background: linear-gradient(90deg, #1cb5e0 0%, #000851 100%);
  // background: white;
  background-size: 400% 400%;
  animation: Gradient 10s ease infinite;
  box-sizing: border-box;

  form {
    // width: 370px;
    height: 600px;
    grid-column: 2;
    grid-row: 2;
    display: grid;
    grid-gap: 10px;
    margin: auto 0;
    padding: 27px;
    // background-color: rgba(255, 255, 255, 0.9);
    background-color: #ecf0f3;
    border-radius: 7px;
    box-shadow: 0 32px 64px rgba(0, 0, 0, 0.4);

    // box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
    .logo {
      // background-image: url(./logo1.png);
      width: 127px;
      height: 90px;
      margin: 0 auto;
    }

    .login-welcome {
      color: black;
    }

    // h1 {
    //   text-align: center;
    //   text-shadow: 0 4px 16px #fff;
    //   font-size: 30px;
    //   font-weight: 100;
    // }
    fieldset {
      width: 100%;
      padding: 10px 0px 5px 0px;

      .username,
      .password {
        display: flex;
        height: 60px;
        margin-bottom: 20px;
        border-radius: 30px;
        // box-shadow: 8px 8px 8px #cbced1, -8px -8px -8px #fff;
        box-shadow: 2px 4px 7px #cccfd1, -2px -4px 7px #fff;
        transition: 0.3s all;

        &:hover {
          box-shadow: 2px 4px 7px #bdbfc0, -2px -4px 7px #fff;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        font-size: 18px;
        // color: #555;
      }

      input {
        width: 80%;
        border: none;
        outline: none;
        background: none;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        padding-right: 1rem;
        font-size: 18px;

        &:focus::placeholder {
          color: transparent;
        }
      }

      svg {
        display: block;
        height: 20px;
        margin: auto;
        opacity: 0.7;
      }
    }

    .login-button {
      outline: none;
      border: none;
      cursor: pointer;
      width: 100%;
      height: 60px;
      margin: 0 auto;
      margin-bottom: -10px;
      border-radius: 30px;
      font-size: 20px;
      color: #fff;
      align-items: center;
      text-align: center;
      background-color: #dc3545;
      box-shadow: 3px 3px 8px #b1b1b1, -3px -3px 8px #fff;
      transition: all 0.3s;

      &:hover {
        background-color: #3979e8;
      }
    }

    .send-email-button {
      outline: none;
      border: none;
      cursor: pointer;
      width: 100%;
      height: 60px;
      margin: 0 auto;
      margin-bottom: 20px;
      border-radius: 30px;
      font-size: 20px;
      color: #fff;
      align-items: center;
      text-align: center;
      background-color: #dc3545;
      box-shadow: 3px 3px 8px #b1b1b1, -3px -3px 8px #fff;
      transition: all 0.3s;

      &:hover {
        background-color: #3979e8;
      }
    }

    .create-user {
      text-align: center;
    }
  }
}

// @media only screen and (max-height: 600px) {
//   // form {
//   //   max-height: 90% !important;
//   // }
// }
// @media only screen and (min-width: 420px) {
//   form {
//     h2 {
//       font-size: 40px;
//     }
//     fieldset {
//       ul {
//         li {
//           grid-template-columns: 100px 1fr;
//           label {
//             padding-right: 10px;
//             padding-bottom: 0;
//             text-align: right !important;
//           }
//         }
//       }
//     }
//   }
// }

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
