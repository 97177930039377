.frequency-component {
        margin-right: 1rem;
        height: 10px;
    

    .sort-by {
        color: grey;
        margin-right: 2px;
        cursor:default;
    }

    .frequency-button {
        background-color: #1e1e20;
        border: none;
        color: white;
    }

    .frequency {
        margin-right: 6px;
        cursor: default;
    }

    .Card {
        display: grid;
        //grid-auto-columns: minmax(15rem,auto);
        //grid-template-columns: repeat(auto-fill,minmax(15rem,1fr));
        //grid-gap: 1rem;
       // grid-template-columns: auto auto auto auto;
        //margin-right: 1rem;
        //margin-top: 2rem;
    }

    .container {
        margin-left: 0;
        background-color: #222227;
        min-width: 40%;
        height: auto;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &:hover 
        {
            box-shadow: 0 1px 10px 1px rgba(186, 182, 182, 0.2);
        }
    }


    h4 {
        color: white;
        font-size: 18px;
        margin-top: 10px;
    }

    p {
        color: grey;
        font-size: 12px;
        margin-top: 6px;
    }

    span {
        color: white;
       // width: 150px;
        //word-wrap: break-word;
    }
}