.Setup {
    background-color: #1e1e20;
    min-height: 89vh;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    color: white;
    pre {
        padding: 10px;
        background: #1e1e20;
        color: white;
      }
    
    .title-endpoints {
        color: white;
        padding-top: 20px;
        margin-bottom:20px;
    }

    .total-endpoints,
    .protected,
    .unprotected {
        color: white;
        font-size: 16px;
    }
    .new-user {
        background-color: #dc3545;
        font-size: 16px;
        height: 45px;
        width: 240px;
        border-radius: 10px;
        color: white;
        font-weight: bold;
        border: 0px;
    }

    .total-endpoints {
        margin-left: 30px;
    }

    .title-servers {
        color: white;
        font-size: 24px;
        font-weight: bolder;
    }

    .vertical-line {
        color: grey;
        margin-left: 1rem;
        margin-right: 0.8rem;
    }

    
    .rdt_TableRow, 
    .rdt_TableCol_Sortable, 
    .rdt_TableCol, 
    .rdt_TableFooter,
    .rdt_Pagination,
    .rdt_TableHeadRow{
        background-color: #232328;
        color: #fff;
        // border-bottom: 2px solid #343a40;
    }

    .rdt_Pagination{
        color: white;

        svg {
            fill: white;
        }
    } 

    table {
        width: 100%;
        

        th, td {
            padding: 8px 16px;
            
        }

        th {
            background-color: #343a40;
        }
    }

    .nav-tabs .nav-link {
        color: white;
        &.active {
            background-color: #dc3545;
            color: white
        }
    }    
}