.Policies {
    background-color: #1e1e20;
    width: 100%;
    height: 89vh;
    
    h2 {
        font-weight: bolder;
        font-size: 30px;
        color: white;
        margin-left: 2rem;
        padding-top: 2rem;
        margin-bottom: 0.5rem;
    }

    .total-policies {
        margin-left: 2rem;
        margin-right: 2rem;
        color: grey;
        font-weight: bold;
        font-size: 12px;
    }

    .affecting {
        color: grey;
        font-size: 12px;
    }

    .vertical-line {
        color: grey;
        margin-left: -1.2rem;
        margin-right: 0.8rem;
    }

    .new-policy {
        background-color: #dc3545;
        font-size: 16px;
        height: 45px;
        width: 160px;
        border-radius: 10px;
        color: white;
        font-weight: bold;
        border: 0px;
    }
}