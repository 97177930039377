.container-fluid {
    background-color:#1e1e20 ;
    padding-left: 0;
    padding-right: 0;

    h1 {
        color: white;
        font-weight: bolder;
        margin-left: 23px;   
        margin-bottom: 10px;
    }
    $open-sans: 'Open Sans', 'Helvetica', sans-serif;

    $darkNavy: #213140;
    $teal1: #66B3FB;
    $teal2: #4B9DEA;
    $charcoal: #555555;
    $gold: #B6985A;

    $activeShadow: 0 0 10px rgba($teal1, .5);

    /* MIXINS */
    @mixin focusOutline {outline: dotted 1px #CCC; outline-offset: .45rem;}
    @mixin hideInput {width: 0; height: 0; position: absolute; left: -9999px;}
    @mixin breakpoint($point) {
        @if $point == 1100 {
            @media (max-width: 1100px) { @content ; }
        }
        @else if $point == 800 {
            @media (max-width: 800px) { @content ; }
        }
    }
    .toggle {
        margin: 0 0 0.5rem;
        box-sizing: border-box;
        font-size: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: stretch;

        input {
            @include hideInput;
        }

        input + label {
            margin: 0;
            color: #232328;
            padding: .75rem 0.5rem;
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            border: solid 1px #DDD;
            background-color: #FFF;
            font-size: 14px;
            line-height: 100%;
            font-weight: 600;
            text-align: center;
            box-shadow: 0 0 0 rgba(255, 255, 255, 0);
            transition: border-color .15s ease-out,
            color .25s ease-out,
            background-color .15s ease-out,
            box-shadow .15s ease-out;

            /* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
            /*flex: 0 0 50%; display: flex; justify-content: center; align-items: center;*/
            /* ----- */

            &:first-of-type {
                border-radius: 5px 0 0 6px;
                border-right: none;
            }

            &:last-of-type {
                border-radius: 0 6px 6px 0;
                border-left: none;
            }
        }

        input:hover + label {
            border-color: rgb(220, 53, 69);
        }

        input:checked + label {
            background-color:rgb(220, 53, 69);
            color: #FFF;
            box-shadow: $activeShadow;
            border-color: rgb(220, 53, 69);
            z-index: 1;
        }

        //input:focus + label {
        //    @include focusOutline;
        //}

        @include breakpoint(800) {
            input + label {
                padding: .75rem .25rem;
                flex: 0 0 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    /* END CHECKBOX STYLE */
    .new-user {
        background-color: #dc3545;
        font-size: 14px;
        height: 30px;
        width: 300px;
        border-radius: 10px;
        color: white;
        font-weight: bold;
        border: 0px;
    }
    .total-endpoints,
    .protected,
    .alerts-filter-radio,
    .unprotected {
        color: white;
        font-size: 16px;
    }

    .total-endpoints {
        margin-left: 30px;
    }

    .span-1, .span-2 {
        color: white;
        margin-right: 6px;
    }

    .vertical-line {
        color: grey;
    }

    .rdt_TableRow,
    .rdt_TableCol_Sortable,
    .rdt_TableCol,
    .rdt_TableFooter,
    .rdt_Pagination,
    .rdt_TableHeadRow {
        background-color: #232328;
        color: #fff;
        // border-bottom: 2px solid #303035;
    }
    .rdt_TableRow > div:first-child > div{
        // color: #0865c9;
        color: white;
    }
    .rdt_Pagination {
        color: white;
        
        svg {
            fill: white;
        }
    }

    .container {
        display: flex;
        margin-bottom: 100px;
        flex: 2;
        min-width: 85vw;
        flex: 1;
        justify-content: center;
        height: 50vh;
    }

    .container-tops{
        display: flex;
        width:100%;
        padding:1rem;
        min-height: 55vh;
        column-gap:1vw;
        &-attacked-endpoint, &-protected-apps, &-threat-types{
            flex-grow: 1;

            display:flex;
            flex-direction: column;
            p {
                margin: 0 0 0.5rem 0rem;
                color:#ffffff;
                font-size:16px;
                font-weight: 900;

                @media (min-width:1050px){
                    margin: 0 0 0.5rem 0.5rem;
                    font-size:20px;
                }

                @media (min-width:1465px){
                    font-size:25px;
                }
            }
            &-content{
                background-color: #222227;
                border-radius: 6px;
                height:100%;
            }
        }

        &-attacked-endpoint, &-protected-apps{
            &-content{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                section{
                    min-height: 11.5vh;
                    flex-direction: row;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width:90%;
                    margin: 0 auto;
                    border-bottom: 2px solid #303035;
                    &:nth-child(4){
                        border-bottom: none;
                    }
                    @media (min-width:1465px){
                        width:80%;
                    }
                    @media (min-width:1050px){
                        width:85%;
                    }
                }
                
            }
        }
        &-threat-types{
            flex-grow: 1;
            @media (min-width:1400px){
                width:40%;
            }
           
            &-content{
                height:100%;
                display:flex;
                justify-content: center;
                align-items: center;
                div:nth-child(1){
                    width:65%;
                }
                div:nth-child(2){
                    width:90%;
                    height:100%;
                }

                @media (min-width:1400px){
                div:nth-child(1){
                    width:30%;
                }
                div:nth-child(2){
                    width:50%;
                    height:90%;
                    padding-right:1rem;
                }
                column-gap:1rem;
            }
                @media (max-width:1400px){
                    flex-direction: column;
                    
                }
            }
        }
        
    }

    .container p {
        color: white;
        font-size: 20px;
        font-weight: bolder;
        margin-bottom: 20px;
    }

    .container section {
        
    }

    .container section p {
        color: grey;
        font-size: 12px;
    }

    .top-attacked-endpoint {
        width: 20%;
        background-color: rgba(186, 182, 182, 0.2);
        justify-content: center;
        align-items: center;
        height: 30rem;
        border-radius: 6px;
    }

    .top-attacked-endpoint p {
        text-align: left;
        background-color: #1e1e20;
        padding-bottom: 30px;
        padding-top: 40px;
    }

    .top-attacked-endpoint section {
        width: 90%;
        height: 4rem;
        border-bottom: 2px solid rgb(91, 91, 91);
        align-items: center;
        display: table;
        padding-left: 20px;
        padding-right: 20px;
        align-items: baseline;
        margin-left: 3%;
        margin-top: 7%;
    }

    .top-threat-types {
        width: 60%;
        background-color: rgba(186, 182, 182, 0.2);
        margin: 20px;
        height: 28.7rem;
        border-radius: 6px;
    }

    .top-threat-types p {
        text-align: left;
        background-color: #1e1e20;
        padding-bottom: 30px;
        padding-top: 20px;
    }

    .top-protected-apps {
        width: 20%;
        background-color: rgba(186, 182, 182, 0.2);
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        height: 30rem;
    }

    .top-protected-apps p {
        text-align: left;
        background-color: #1e1e20;
        padding-bottom: 30px;
        padding-top: 40px;
    }

    .top-protected-apps section {
        width: 90%;
        height: 4rem;
        border-bottom: 2px solid rgb(91, 91, 91);
        align-items: center;
        display: table;
        padding-left: 20px;
        padding-right: 20px;
        align-items: baseline;
        margin-left: 3%;
        margin-top: 7%;
    }

    .span-1 {
        align-items: center;
        margin-left: 30px;
        justify-content: center;
        vertical-align: middle;
        display: table-cell;
    }

    .span-2 {
        vertical-align: middle;
        display: table-cell
    }

}