.btn-default {
    background-color: #dc3545 ;
    border: none;
    font-weight: 500;
    border-radius: 10px;
    font-size: 16px;
    height: 50px;
    width: 100px;
    margin-right: 70px;
}

.btn-logout {
    border: none;
    font-weight: 900;
    background-color: inherit;
}
