.Profile {
    background-color: #1e1e20;
    width: 100%;
    height: 89vh;

    h2 {
        font-weight: bolder;
        font-size: 30px;
        color: white;
        margin-left: 2rem;
        padding-top: 2rem;
    }

    .role {
        margin-left: 2rem;
        margin-right: 2rem;
        color: grey;
        font-weight: bold;
        font-size: 12px;
    }

    .member-since {
        color: grey;
        font-size: 12px;
    }

    .vertical-line {
        color: grey;
        margin-left: -1.2rem;
        margin-right: 0.8rem;
    }

    label {
        text-align: right;
        clear: both;
        float: left;
        margin-right: 15px;
        color: white;
    }

    input {
        width: 20rem;
        background-color: #343a40;
        border-radius: 7px;
        color: grey;
        border: 2px solid rgb(76, 76, 76);
    }

    .first-last-name {
        color: white;
        margin-left: 8rem;
        font-weight: 900;
        margin-top: -4rem;
        margin-bottom: 5rem;
    }

    .update-profile {
        background-color: #dc3545;
        height: 40px;
        width: 140px;
        color: white;
        font-size: 13px;
        border: none;
        border-radius: 8px;
        margin-left: 16px;
        display: inline;
        font-weight: 900;
    }

    .logout {
        margin-left: 20px;
        background-color: inherit;
        border: none;
        color: white;
        font-size: 13px;
        font-weight: 900;
    }
}