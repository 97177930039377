.Endpoints {
    background-color: #1e1e20;
    height: 89vh;
    width: 100%;

    .input{
        color:white;
        background-color: #343a40
    }

    .title-container {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        margin-bottom: 20px;
        
        .title-endpoints {
            color: white;
        }
    }

    .total-endpoints,
    .protected,
    .unprotected {
        color: white;
        font-size: 16px;
    }

    .title-servers {
        color: white;
        font-size: 24px;
        font-weight: bolder;
        margin-left: 0px;
        margin-bottom: 10px;
    }

    .vertical-line {
        color: grey;
        margin-left: 1rem;
        margin-right: 0.8rem;
    }

    
    .rdt_TableRow, 
    .rdt_TableCol_Sortable, 
    .rdt_TableCol, 
    .rdt_TableFooter,
    .rdt_Pagination,
    .rdt_TableHeadRow{
        background-color: #232328;
        color: #fff;
        // border-bottom: 2px solid #343a40;
    }

    .rdt_Pagination{
        color: white;

        svg {
            fill: white;
        }
    } 

    table {
        width: 100%;
        

        th, td {
            padding: 8px 16px;
            height: 51px;
            
        }

        th {
            background-color: #343a40;
        }
    }
}