.Endpoints {
    // height: 89vh;
    width: 100%;
    overflow-y: scroll;

    $open-sans: 'Open Sans', 'Helvetica', sans-serif;
    $teal1: #66B3FB;
    $teal2: #4B9DEA;
    $charcoal: #555555;
    $gold: #B6985A;

    $activeShadow: 0 0 10px rgba($teal1, .5);

    /* MIXINS */
    @mixin focusOutline {outline: dotted 1px #CCC; outline-offset: .45rem;}
    @mixin hideInput {width: 0; height: 0; position: absolute; left: -9999px;}
    @mixin breakpoint($point) {
        @if $point == 1100 {
            @media (max-width: 1100px) { @content ; }
        }
        @else if $point == 800 {
            @media (max-width: 800px) { @content ; }
        }
    }
    .toggle {
        margin: 0 0 0.5rem;
        box-sizing: border-box;
        font-size: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: stretch;

        input {
            @include hideInput;
        }

        input + label {
            margin: 0;
            color: #232328;
            padding: .75rem 0.5rem;
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            border: solid 1px #DDD;
            background-color: #FFF;
            font-size: 14px;
            line-height: 100%;
            font-weight: 600;
            text-align: center;
            box-shadow: 0 0 0 rgba(255, 255, 255, 0);
            transition: border-color .15s ease-out,
            color .25s ease-out,
            background-color .15s ease-out,
            box-shadow .15s ease-out;

            /* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
            /*flex: 0 0 50%; display: flex; justify-content: center; align-items: center;*/
            /* ----- */

            &:first-of-type {
                border-radius: 5px 0 0 6px;
                border-right: none;
            }

            &:last-of-type {
                border-radius: 0 6px 6px 0;
                border-left: none;
            }
        }

        input:hover + label {
            border-color: rgb(220, 53, 69);
        }

        input:checked + label {
            background-color:rgb(220, 53, 69);
            color: #FFF;
            box-shadow: $activeShadow;
            border-color: rgb(220, 53, 69);
            z-index: 1;
        }

        //input:focus + label {
        //    @include focusOutline;
        //}

        @include breakpoint(800) {
            input + label {
                padding: .75rem .25rem;
                flex: 0 0 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    /* END CHECKBOX STYLE */
    h1 {
        color: white;
        font-weight: bolder;
    }
    .new-user {
        background-color: #dc3545;
        font-size: 14px;
        height: 30px;
        width: 300px;
        border-radius: 10px;
        color: white;
        font-weight: bold;
        border: 0px;
    }

    .ostype,
    .heartbeat,
    .alerts-filter-radio,
    .protection {
        color: white;
        font-size: 16px;
    }

    .vertical-line {
        color: grey;
    }

    .os-type,
    .last-heartbeat{
        color: white;
        font-size: 10px;
        margin-left: 35px;
    }

    .last-heartbeat,
    .protection {
        margin-left: 10px;
    }

    .policy-assigned {
        margin-top: 20px;
        color: white;
        margin-bottom: 10px;
    }

.upper-divv2{
    display:flex;
    flex-direction: column;
    gap: 1rem;
    height:max-content;
    margin: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
    align-items: stretch;
    .doughnut-container, .bar-container{
        background-color: #232328;
        height:90%;
        border-radius: 10px;
        min-height: 100px;
    }
    .doughnut-container{      
        min-height: 250px;  
        padding: 0rem 25%;
        @media (max-width:800px) {
            padding:1rem 15%;
        }
    }
    .bar-container{
        padding: 2% 5% 2% 3%;
    }
    @media (min-width:1000px) {
    max-height:350px;
    margin: 0 2rem;
    flex-direction: row;
    margin-bottom: 3rem !important;
    
    }
}

.upper-div{
    display:flex;
    flex-direction: column;
    gap: 1rem;
    height:max-content;
    margin: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
    align-items: stretch;
    .doughnut-container, .bar-container{
        background-color: #232328;
        height:90%;
        border-radius: 10px;
        min-height: 100px;
    }
    .doughnut-container{      
        min-height: 250px;  
        padding: 0rem 25%;
        @media (max-width:800px) {
            padding:1rem 15%;
        }
    }
    .bar-container{
        padding: 2% 5% 2% 3%;
    }
    @media (min-width:1000px) {
    max-height:350px;
    margin: 0 2rem;
    flex-direction: row;
    margin-bottom: 3rem !important;
    .left-side, .right-side{
        
        width:50%;
        // flex-grow: 1;
        .doughnut-container, .bar-container{
            background-color: #232328;
            height:100%;
            border-radius: 10px;
        }

        .doughnut-container{
            min-height: initial;
            padding: 0rem 15%;
        }
    }
    .right-side{
        margin-top:43px;
    }
    }
}

    h3 {
        color: white;
        margin-left: 1rem;
        margin-bottom: 10px;
        height: 3%;
    }


    .lower-div {
        height: 48%;
    }


    h4 {
        color: white;
        margin-bottom:0.5rem;
    }

    .capacity-label{
        position: relative;

        p {
            position: absolute;
            font-size: 100px;
            font-weight: bolder;
            margin-top: 6rem;
            margin-left: 26%;
        }

        h4 {
            position: absolute;
            font-size: 25px;
            margin-top: 14rem;
        }

        @media (max-width: 1600px) {
            p {
                margin-left: 20%;
            }
        }

        @media (max-width: 1400px) {
            p {
                font-size: 70px;
                margin-left: 23%;
            }

            h4 {
                font-size: 17px;
                margin-top: 11.5rem;
            }
        }

        @media (max-width: 1200px) {
            p {
                font-size: 55px;
                margin-left: 20%;
            }

            h4 {
                font-size: 14px;
                margin-top: 10.5rem;
            }
        }

        @media (max-width: 900px) {
            p {
                font-size: 30px;
                margin-left: 19%;
            }
        }
    }
}