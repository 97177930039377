.Users {
    background-color: #1e1e20;
    width: 100%;
    height: 89vh;
    color: white;

    h1 {
        font-weight: bolder;
    }

    .total-users,
    .connected{
        color: white;
        font-size: 10px;
        font-size: 12px;
        color: grey;
    }

    .vertical-line {
        color: grey;
        margin-left: 1rem;
        margin-right: 0.8rem;
    }

    .new-user {
        background-color: #dc3545;
        font-size: 16px;
        height: 45px;
        width: 160px;
        border-radius: 10px;
        color: white;
        font-weight: bold;
        border: 0px;
    }

    .rdt_TableRow,
    .rdt_TableCol_Sortable,
    .rdt_TableCol,
    .rdt_TableFooter,
    .rdt_Pagination,
    .rdt_TableHeadRow {
        background-color: #232328;
        color: #fff;
    }

    .rdt_Pagination {
        color: white;

        svg {
            fill: white;
        }
    }

}